export default class ReportService {
        
  static buildReport(reportID, _vals, reportMime, reportName, store) {
        console.log('sending rep values:', reportID, _vals);

        var d = store.getters['period/current'];
        var ctx = 'param{name:"dateWork",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
        d = store.getters['period/begin'];
        ctx += 'param{name:"dateBegin",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
        d = store.getters['period/end'];
        ctx += 'param{name:"dateEnd",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}}';
        const formId = 'jet-form-' + reportID;

        $.ajax({
          url: '/rpc?d=report',
          contentType: "application/x-www-form-urlencoded; charset=UTF-8",
          data: '&report=' + reportID + '&args=' + '@' + _vals + '&ctx=' + '@[' + ctx + ']',
          processData: false,
          type: 'POST',
          xhrFields: {responseType: 'blob'}
        }).then((data, status, request)=>{
          var ext = request.getResponseHeader('content-disposition') || '.xls';
          ext = ext.substr(ext.lastIndexOf('.'));
          var url = window.URL.createObjectURL(new Blob([data],  {type : reportMime}));
          var ref = document.createElement('a');
          ref.href = url;
          ref.download = reportName + ext;
          document.body.appendChild(ref);
          ref.click();
          document.body.removeChild(ref);
          window.URL.revokeObjectURL(url);
        }).catch((err)=>{
          this.err = err;
          console.log('report error', reportID, err);
        });
    }
}


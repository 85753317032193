<template>
  <div/>
</template>

<script>
import BaseAction from '@/components/actions/BaseAction';
import RouteService from '@/services/RouteService';
import ReportService from '@/components/services/ReportService';

export default {
  mixins: [
    BaseAction,
  ],
  name: 'OpenReport_10_1_2',
  mounted() {
    if (!this.selected) {
      return;
    }
    this.open();
  },
  methods: {
    open() {
      var cardID = this.selected.vccardregisterId;
      var store = this.$store;
      var reportID = "62b28f5d-9f33-46fe-b42c-b248925eefda";
      var _vals = `{"cardID":"${cardID}"}`;
      this.$nextTick(()=>{
        ReportService.buildReport(reportID, _vals, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '10.1.2 Карта маршрута (КК)', store); 
      });
    },
  },
}
</script>